<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
        <v-row justify="center" class="pt-5">
            <!-- TODO: realm name and logo? -->
            <!-- <h1 v-show="loginUsernameInput" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && !isActivatingLoginShield" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithLoginShield && isActivatingLoginShield" class="text-h4 font-weight-light">Account</h1> -->
            <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Account</h1>
            <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="4" class="px-10 py-5 pt-10 mt-5" v-if="pending">
                    <p v-if="pending">Please wait...</p>
                    <!-- <p v-if="serviceError" class="red--text">Service unavailable. Please try again later.</p>
                    <p v-if="tokenError" class="red--text">The link was invalid or expired.</p> -->
                </v-card>
                <v-alert border="left" color="red darken-2" colored-border v-if="serviceError">
                    Service unavailable. Please try again later.
                </v-alert>
                <v-alert border="left" color="red darken-2" colored-border v-if="tokenError">
                    The link was invalid or expired.
                </v-alert>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { INTENT_LOGIN, INTENT_RECOVERY, INTENT_REDIRECT, RESPONSE_TYPE_FAULT, RESPONSE_TYPE_INTENT } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';

export default {
    data() {
        return {
            pending: null,
            serviceError: false,
            tokenError: false,
        };
    },

    watch: {
        // isReady(value, oldValue) {
        //     if (value && !oldValue) {
        //         this.init();
        //     }
        // },
    },

    computed: {
        ...mapState({
            // isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    methods: {
        init() {
            // if a token is provided, the user arrived here after clicking a link in an email
            // (or transferring that link to themselves some other way and then opening it)
            if (this.$route.query.cryptium_id_token) {
                this.activate();
            }
        },
        resetErrors() {
            this.serviceError = false;
            this.tokenError = false;
        },
        async activate() {
            try {
                this.$store.commit('loading', { activate: true });
                const { type, fault, intent, intent_params: intentParams } = await this.$client.realm(this.$route.params.realm).authn.activateEmailToken({
                    token: this.$route.query.cryptium_id_token,
                });
                if (type === RESPONSE_TYPE_FAULT) {
                    console.error(`activate: server returned fault: ${JSON.stringify(fault)}`);
                    this.tokenError = true;
                    return;
                }
                if (type === RESPONSE_TYPE_INTENT) {
                    switch (intent) {
                    case INTENT_LOGIN: {
                        const { id, email } = intentParams;
                        this.$router.replace({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { r: id, email } });
                        return;
                    }
                    case INTENT_REDIRECT: {
                        const { redirect } = intentParams;
                        if (!redirect) {
                            console.error('activate: intent redirect did not include redirect param');
                            this.tokenError = true;
                            return;
                        }
                        console.log(`activate: redirect ${redirect}`);
                        if (typeof window.location.replace === 'function') {
                            window.location.replace(redirect);
                        } else {
                            window.location.href = redirect;
                        }
                        return;
                    }
                    case INTENT_RECOVERY: {
                        const { id, email } = intentParams;
                        this.$router.replace({ name: 'realm-access-recovery', params: { realm: this.$route.params.realm }, query: { id, email } });
                        return;
                    }
                    default:
                        console.log(`activate: unrecognized intent ${intent}`);
                        this.tokenError = true;
                    }
                }
                /*
                if (next) {
                    console.log(`activate: next ${JSON.stringify(next)}`);
                    this.$router.replace(next);
                    return;
                }
                if (error) {
                    this.tokenError = true;
                    return;
                }
                console.error('activate: unexpected response from server, no redirect and no error');
                */
            } catch (err) {
                console.error('activate failed', err);
                this.serviceError = true;
            } finally {
                this.$store.commit('loading', { activate: false });
            }
        },
        // async updateSession({ isAuthenticated }) {
        //     this.$store.commit('setSession', { ...this.session, isAuthenticated });
        //     if (isAuthenticated) {
        //         await this.$store.dispatch('loadUser');
        //     } else {
        //         this.$store.commit('setUser', {});
        //     }
        // },
    },

    mounted() {
        this.init();
    },
};
</script>
